<template>
  <div class="pay">
    <div class="address">
      <div class="card-head">
        <span>{{ $t('pay.shrxx') }}</span>
        <span @click="management">{{ $t('pay.glshrdz') }}</span>
      </div>
      <div class="address-manage">
        <div class="address-item" v-show="moreAddr ? true : index < 3" v-for="(item, index) in addressList"
          :class="selectedAddress === item.id ? 'border-red' : ''" :key="index" @mouseenter="showEditBtn = index"
          @mouseleave="showEditBtn = ''" @click="selectAddress(item)">
          <div>
            <span>{{ item.name }}</span>
            <!-- <Tag class="ml_10" v-if="item.isDefault" color="red">默认</Tag>
              <Tag class="ml_10" v-if="item.alias" color="warning"
                >{{ item.alias }}
              </Tag> -->
          </div>
          <div>{{ item.mobile }}</div>
          <div>
            {{ item.consignee_address_path }}{{ item.detail }}
          </div>
          <div class="edit-btn" v-show="showEditBtn === index">
            <span @click.stop="editAddress(item)">{{ $t('pay.xg') }}</span>
            <span class="ml_10" @click.stop="delAddress(item)">{{ $t('pay.sc') }}</span>
          </div>
          <div class="corner-icon" v-show="selectedAddress === item.id">
            <div></div>

            <i class="el-icon-check"></i>
          </div>
        </div>
        <div class="add-address" @click="addAddress">
          <i class="el-icon-circle-plus-outline"></i>
          <div>{{ $t('pay.tjxdz') }}</div>
        </div>
      </div>
      <div class="more-addr" @click="moreAddr = !moreAddr" v-if="addressList.length > 3">
        {{ moreAddr ? $t('pay.sqdz') : $t('pay.gddz') }}
        <i v-show="!moreAddr" class="el-icon-caret-bottom"></i>
        <i v-show="moreAddr" class="el-icon-caret-top"></i>
      </div>
    </div>
    <div class="goods-content">
      <div class="card-head mt_20 mb_20">
        <span>{{ $t('pay.spxx') }}</span>
        <span @click="$router.push('/shopping')">{{ $t('pay.fhgwc') }}</span>
      </div>
      <div class="goods-msg" :style="smallScreen ? { 'margin-bottom': (bottomdistance + 74) + 'px' } : {}"
        v-for="(shop, shopIndex) in clearingGoods" :key="shopIndex">
        <div>
          <div class="shop-name">
            <span>
              <span class="hover-color" @click="goShopPage(shop.storeId)">{{
}}</span>&nbsp;&nbsp;
            </span>
          </div>
          <div class="goods-list">
            <div class="goods-item">
              <span class="hover-color">
                <img :src="shop.product_icon" alt="" />
                <span style="vertical-align: top">{{
                  shop.product_name
                }}</span>
              </span>
              <span class="goods-price">
                {{ moneyfilter(shop.product_price) }}
              </span>
              <span>x{{ shop.product_num }}</span>
              <span>{{ 123 > 0 ? $t('pay.yh') : $t('pay.wu') }}</span>
              <span class="goods-price">
                {{ moneyfilter(shop.subtotal) }}
              </span>
            </div>
          </div>
          <div class="order-mark">
            <el-input type="textarea" :rows="2" v-model="shop.remark" :autosize="{ minRows: 1, maxRows: 4 }"
              :placeholder="$t('pay.ddbz')">
            </el-input>
            <span style="font-size: 12px; color: #999">{{ $t('pay.ts') }}</span>
          </div>
        </div>
      </div>
      <div class="order-footer width_1200" :style="smallScreen ? { bottom: bottomdistance + 'px' } : {}">
        <!-- <div class="pays ml_20" @click="pay">{{ $t('pay.tjdd') }}</div> -->
        <div class="pays ml_20" @click="tj">{{ $t('pay.tjdd') }}</div>
        <div class="pay-address" v-if="clearingGoods.length" @click="detail">
          {{ $t('pay.psz') }}：{{ pitchAddress.consignee_address_path }}{{ pitchAddress.detail }}&nbsp;&nbsp;{{
            $t('pay.shr') }}：{{ pitchAddress.name }}&nbsp;&nbsp;{{
}}
        </div>
        <div class="total">{{ moneyfilter(total) }}</div>
      </div>
    </div>
    <address-manage ref="address" :addressShow="addressShow" @addCancel="addCancel" :delList="delList"
      @transfer="transfer"></address-manage>
    <div v-if="showDetail" @click.prevent="showDetail = false"
      style="position:fixed;width:100%;height:100%;left: 0;top: 0;background-color: rgba(0,0,0, .4);display: flex;align-items: center;justify-content: center;">
      <div
        style="width:85%;background: #fff;border-radius: 3px; padding:20px 10px;box-sizing: border-box;font-size: 18px;">
        <div style="margin-bottom: 20px;">
          {{ $t('pay.shdz') }}：{{ pitchAddress.consignee_address_path }}{{ pitchAddress.detail }}
        </div>
        <div>
          {{ $t('pay.shr') }}：{{ pitchAddress.name }}
        </div>
        <div>收货人：{{ pitchAddress.name }}</div>
      </div>
    </div>
    <el-dialog :title="$t('pay.zffs')" :visible.sync="dialogVisible" width="30%">
        <el-form ref="form" :model="form" label-width="180px">
          <el-form-item :label="$t('pay.qxzndzffs')">
            <el-select v-model="form.payType" :placeholder="$t('pay.qxzndzffs')" style="width:360px;">
              <el-option :label="$t('pay.jnzf')" value="DomesticPayment"></el-option>
              <el-option :label="$t('pay.jwzf')" value="InternationalPayments"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item style="margin-left:200px">
            <el-button @click="dialogVisible = false">{{ $t('pay.qx') }}</el-button>
            <el-button type="primary" @click="onSubmit">{{ $t('pay.qr') }}</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
  </div>
</template>

<script>
import addressManage from "../../components/addressManage";
export default {
  name: "pay",
  components: { addressManage },
  data() {
    return {
      addressList: [],
      goodsList: [3],
      selectedAddress: null,
      showEditBtn: "", // 鼠标移入显示编辑按钮
      moreAddr: false, // 更多地址
      addressShow: false,
      mAddressShow: false,
      clearingGoods: [],
      total: null,
      ordersGoods: [],
      delList: null,
      pitchAddress: [],
      wx: false,
      showDetail: false,
      smallScreen: false,
      bottomdistance: 0,
      dialogVisible: false,
      form: {
        payType: ""
      }
    };
  },

  mounted() {

    var clientWidth = document.documentElement.clientWidth;
    if (clientWidth < 750) {
      this.smallScreen = true
      this.bottomdistance = clientWidth / 6.405
    }
    // if ((navigator.userAgent.indexOf('Weixin') != -1 || navigator.userAgent.indexOf('wechat') != -1)) {
    //   this.wx = true
    // }
    this.clearingGoods = JSON.parse(localStorage.getItem("commodityInfo"));
    this.clearingGoods.forEach((item) => {
      let data = {
        code: item.product_id,
        num: item.product_num,
      }
      this.ordersGoods.push(data)
    })
    this.accAdd(this.clearingGoods)
    this.getAddressList()
    console.log(localStorage.getItem('order_no'))
  },

  methods: {
    async onSubmit() {
      console.log(1)
      this.dialogVisible = false
      console.log(this.form.payType)
      if (this.form.payType == "DomesticPayment") {
        console.log('ok')
        this.pay()
      }

      if (this.form.payType == "InternationalPayments") {
        var  href = location.href + '/MyOrder'
        console.log(href)
        var order_no =localStorage.getItem('order_no')
        // 156067938548318208
        let data = {
          orderNo:JSON.parse(order_no),
          frontURL:href
        }
        console.log('no')
        let InternationalPayments = await this.api.post(this.apiPath.InternationalPayments, data);
        console.log(InternationalPayments)
      }
    },
    tj() {
      this.dialogVisible = true
    },
    detail() {
      if (document.documentElement.clientWidth < 750) {
        this.showDetail = !this.showDetail;
      }
    },
    accAdd(arr) {
      const fieldArr = []
      for (var i = 0; i < arr.length; i++) {
        var m, field;
        try {
          field = arr[i].subtotal.toString().split(".")[1].length;
        } catch (e) {
          field = 0;
        }
        fieldArr.push(field);
      }
      m = Math.pow(10, Math.max(...fieldArr));
      let add = 0;
      arr.forEach(function (item) {
        add = add + item.subtotal * m;
      });
      const sum = Math.round(add) / m;
      this.total = sum;
    },
    async getAddressList() {
      console.log('获取地址列表')
      let res = await this.api.post(this.apiPath.addresslist, {});
      console.log(res)
      this.addressList = res.data
      this.addressList.forEach(item => {
        if (item.is_default) {
          this.selectedAddress = item.id;
          this.pitchAddress = item;
        }
      });
      if (!this.addressList.some((item) => item.is_default)) {
        this.selectedAddress = this.addressList[this.addressList.length - 1].id;
        this.pitchAddress = this.addressList[this.addressList.length - 1];
      }
    },
    selectAddress(item) {
      this.pitchAddress = item
      this.selectedAddress = item.id
    },
    editAddress(item) {
      //修改地址

      this.delList = item;
      setTimeout(() => {
        this.addressShow = true;
      });
    },
    addCancel(msg) {
      this.addressShow = msg
      this.mAddressShow = msg
    },
    transfer() {
      this.getAddressList()
    },
    management() {
      this.$router.push({
        name: "MyAddress",
      });
    },
    async pay() {
      // 提交订单
      this.clearingGoods.forEach(i => {
        this.ordersGoods.forEach(j => {
          // console.log(i+ '----' + j)
          if (j.product_id == i.code) {
            if (i.remark) {
              j.remark = i.remark;
            }
          }
        });
      });
      const buyType = localStorage.getItem("buyType");
      let data = {
        total_price: this.total,
        address_id: this.selectedAddress,
        carts: this.ordersGoods,
        buy_type: buyType * 1,
      };

      let res = await this.api.post(this.apiPath.createOrder, data);
      // this.$message(res.message);
      if (res.code == 200) {
        console.log(data);
        localStorage.setItem("order_no", JSON.stringify(res.data.order_no));
        this.$router.push({
          name: "payment",
        });
        let num = Math.round(Math.random() * 1100);
        localStorage.setItem("shoppingNum", num);
      } else {
        this.$message({
          message: res.message,
          type: "warning",
        });
      }
    },
    async delAddress(item) {
      //删除地址
      let data = {
        id: item.id,
      };
      let res = await this.api.post(this.apiPath.delAddress, data);
      this.transfer();
    },
    addAddress() {
      this.addressShow = true;
    },
    addAddress() {
      this.addressShow = true
    }
  },
};
</script>

<style lang="less" scoped>
/deep/ input.el-input__inner {
  padding: 0 15px;
}

.pay {
  height: 100%;
  padding: 40px;
  .address {
    display: flex;
    flex-direction: column;
  }
}

// .detailsmodal {
//   display: none;
// }
.card-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dddddd;
  height: 40px;

  span:nth-child(1) {
    font-size: 18px;
  }

  span:nth-child(2) {
    font-size: 12px;
    color: #438cde;
    cursor: pointer;

    &:hover {
      color: red;
    }
  }
}

.address-manage {
  display: flex;
  flex-wrap: wrap;

  >div {
    border: 1px dotted #949494;
    width: 265px;
    height: 120px;
    margin: 20px 20px 0 0;
    padding: 10px;
    cursor: pointer;
    color: #999;
  }

  .add-address {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .el-icon-circle-plus-outline {
      font-size: 24px;
    }
  }

  .address-item {
    position: relative;
    font-size: 12px;

    >div:nth-child(1) {
      margin-bottom: 10px;

      span {
        margin-right: 10px;
      }

      >span:nth-child(1) {
        color: #000000;
        font-size: 14px;
      }
    }

    .edit-btn {
      font-size: 12px;
      position: absolute;
      top: 15px;
      right: 20px;
      color: red;

      .ml_10 {
        margin-left: 10px;
      }

      span:hover {
        border-bottom: 1px solid red;
      }
    }

    .corner-icon {
      position: absolute;
      right: -1px;
      bottom: -1px;

      div {
        width: 0;
        border-top: 20px solid transparent;
        border-right: 20px solid red;
      }

      .el-icon-check {
        font-size: 12px;
        position: absolute;
        bottom: 0;
        right: 1px;
        transform: rotate(-15deg);
        color: #fff;
      }
    }
  }

  .border-red {
    border-color: red;
  }
}

.more-addr {
  margin-top: 20px;
  cursor: pointer;
}

.goods-content {
  margin-top: 20px;

  .goods-msg {
    margin-top: 20px;

    .order-mark {
      width: 30%;
    }
  }

  .card-head {
    margin-top: 20px;
  }
}

.goods-list {
  width: 80%;
  background-color: #f8f8f8;
  margin: 10px 0 20px 0;

  .goods-item {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    margin: 0 5px;
    border-bottom: 1px dotted #999;

    &:last-child {
      border: none;
    }

    img {
      width: 48px;
      height: 48px;
    }

    >span {
      text-align: center;
      width: 100px;
    }

    >span:nth-child(1) {
      font-size: 12px;

      flex: 1;
      text-align: left;

      >span {
        margin-left: 10px;
      }
    }

    >span:last-child {
      color: red;
      font-weight: bold;
    }

    .goods-price {
      font-size: 15px;
    }
  }
}

.order-footer {
  z-index: 20;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  border-top: 1px solid #ddd;
  margin: 10px auto;
  font-size: 15px;

  .total {
    color: #7f0114;
    margin-right: 20px;
  }

  div {
    text-align: center;
  }

  position: sticky;
  bottom: 0;

  .pays {
    background-color: #7f0114;
    height: 50px;
    width: 150px;
    font-size: 20px;
    color: #fff;
    line-height: 50px;
    cursor: pointer;
  }
}

@media screen and (max-width: 750px) {
  .goods-list {
    width: 100%;

    .hover-color {
      width: 200px !important;
    }
    .product-name {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .order-mark {
    width: 100% !important;
  }

  .pays {
    height: 30px !important;
    line-height: 30px !important;
    width: 80px !important;
    font-size: 13px !important;
  }

  .pay-address {
    width: 180px;
    color: #fff;
  }

  .order-footer {
    justify-content: space-evenly;
    height: 100%;
    background: #999;
  }
}

@media screen and (max-width: 750px) {
  .goods-msg {
    margin-bottom: 74px;
  }

  .order-footer {
    position: fixed;
    z-index: 100;
    width: 100%;
    bottom: 0;
    left: 0;
    height: auto;
    margin: 0;
    height: 74px;

    .pay-address {
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden; //溢出内容隐藏
      text-overflow: ellipsis; //文本溢出部分用省略号表示
      display: -webkit-box; //特别显示模式
      -webkit-line-clamp: 2; //行数
      line-clamp: 2;
      -webkit-box-orient: vertical; //盒子中内容竖直排列
    }
  }
}
</style>
<style>
@media screen and (max-width: 750px) {
  .el-dialog {
    width: 94% !important;
    padding: 10px 23px !important;
  }

  .el-form-item label {
    width: 85px !important;
  }

  .el-form-item .el-form-item__content {
    margin-left: 85px !important;
  }

  .el-dialog__body {
    padding: 30px 0;
  }

  /* .detailsmodal {
    display: block;
  } */
}
</style>
