<template>
  <div>
    <el-dialog :title="$t('MyAddress.shdz')"
               :visible.sync="dialogFormVisible"
               :before-close="cancel">
      <el-form :model="form"
               ref="form"
               :rules="ruleInline">
        <el-form-item :label="$t('MyAddress.shr')"
                      :label-width="formLabelWidth"
                      prop="name">
          <el-input v-model="form.name"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('MyAddress.shdq')"
                      :label-width="formLabelWidth"
                      prop="consignee_address_path">
          <el-input v-model="form.consignee_address_path"
                    disabled
                    class="addressinput"></el-input>
          <el-button type="danger"
                     @click="dialogVisible = true">{{$t('MyAddress.xz')}}</el-button>
        </el-form-item>
        <el-form-item :label="$t('MyAddress.xxdz')"
                      :label-width="formLabelWidth"
                      prop="detail">
          <el-input v-model="form.detail"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('MyAddress.sjhm')"
                      :label-width="formLabelWidth"
                      prop="mobile">
          <el-input v-model="form.mobile"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('MyAddress.dzbm')"
                      :label-width="formLabelWidth">
          <el-input v-model="form.alias"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('MyAddress.mrdz')"
                      :label-width="formLabelWidth">
          <el-switch v-model="form.is_default"
                     active-color="#13ce66"
                     inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="cancel">{{$t('shopping.qx')}}</el-button>
        <el-button type="danger"
                   @click="determine">{{$t('shopping.qr')}}</el-button>
      </div>
    </el-dialog>
    <el-dialog title="选择地址"
               :visible.sync="dialogVisible"
               width="850px"
               :before-close="handleClose">
      <lili-map ref="map"
                @getAddress="getAddress"></lili-map>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false">{{$t('shopping.qx')}}</el-button>
        <el-button type="primary"
                   @click="getAddrContent">{{$t('shopping.qr')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import liliMap from '../../components/map';
export default {
  name: "addressManage",
  components: {
    liliMap
  },
  props: {
    addressShow: {
      type: Boolean,
    },
    delList: {
      type: Object,
    }
  },
  data () {
    return {
      form: {
        name: "",
        consignee_address_path: "",
        detail: "",
        is_default: 0,
        mobile: "",
        alias: ""
      },
      dialogFormVisible: false,
      formLabelWidth: "120px",
      dialogVisible: false,
      ruleInline: { // 验证规则
        name: [{ required: true, message: this.$t('MyAddress.qsrsjrxm'), trigger: 'blur' }],
        consignee_address_path: [{ required: true, message: this.$t('MyAddress.qsrdz'), trigger: 'change' }],
        detail: [
          { required: true, message: this.$t('MyAddress.qsrxxdz'), trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: this.$t('MyAddress.sjhbnwk'), trigger: 'blur' },
          {
            type: 'string',
            pattern: /^1[3|4|5|6|7|8][0-9]{9}$/,
            message: this.$t('MyAddress.sjhgscc'),
            trigger: 'blur'
          }
        ]
      },
    };
  },
  watch: {
    addressShow: {
      handler: function (val) {
        this.dialogFormVisible = val;
      },
      immediate: true,
      deep: true,
    },
    delList (curVal, oldVal) {
      if (curVal) {
        this.form = curVal
      }
    },
  },

  mounted () {
  },

  methods: {
    getAddrContent () {
      let addrContent = JSON.parse(localStorage.getItem("addrContent"))
      var reg = /.+?(自治州|县|区)/g // 省市区的正则
      let receiptarea = addrContent.address.match(reg)[0]
      this.dialogVisible = false
      this.form.consignee_address_path = receiptarea
      this.form.detail = addrContent.address
    },
    cancel () {
      this.$emit("addCancel", false);
    },
    async determine () {
      if (this.delList) {
        let res = await this.api.post(this.apiPath.editAddress, this.form);
        if (res.code == 2) {
          this.$message({
            message: this.$t('MyAddress.xgdzcg'),
            type: 'success'
          });
          this.$emit('transfer', true)
        } else {
          this.$message.error(res.message);
        }
      } else {
        console.log(this.$t('MyAddress.xinz'));
        let res = await this.api.post(this.apiPath.address, this.form);
        if (res.code == 1) {
          this.$message({
            message: this.$t('MyAddress.tjdzcg'),
            type: 'success'

          });
          this.form = {
            name: "",
            consignee_address_path: "",
            detail: "",
            is_default: 0,
            mobile: "",
            alias: ""
          }
          this.$emit('transfer', true)
        } else {
          this.$message.error(res.message);
        }
      }
      this.$emit("addCancel", false);
    },
    handleClose (done) {
      done();
    },
    getAddress (item) {
      // 获取地图选择信息
      console.log(item);
      // this.mapMsg = item;
      // this.$set(this.formData, 'address', item.addr);
      // this.$set(this.formData, 'consigneeAddressIdPath', item.addrId);
      // this.$set(this.formData, 'detail', item.detail);
      // this.formData.lat = item.position.lat;
      // this.formData.lon = item.position.lng;
    }
  },
};
</script>

<style lang="less" scoped>
</style>
<style>
.addressinput {
  width: 100%;
}
</style>